import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AUTH_ROUTES_URL } from "constant/routers";
import { forgottenStep } from "store/auth/actions";
import { StoreTypes } from "store/types";
import { STEP_FORGOTTEN } from "constant/step-auth";
import history from "services/history";
import { Button, Logo, Text, Wrapper } from "components/ui";
import { isHyundaiTheme, sizeTitleAuth, titleCompanyTheme } from "components/ui/switch-themes";
import { authLogo } from "components/ui/helpersComponents";
import styles from "./forgotten.module.scss";

const ForgottenSuccess: FC<ReduxProps> = ({ resetStep, redirect }) => {
  // TEMPLATE

  const onStep = () => {
    history.push(AUTH_ROUTES_URL.SIGN_IN);
    resetStep(STEP_FORGOTTEN.TELEPHONE);
  };
  return (
    <div className="text-center">
      {authLogo}
      <Wrapper pb={48}>
        <Text uppercase={!isHyundaiTheme} size={sizeTitleAuth} align="center" theme={titleCompanyTheme}>
          Восстановление пароля
        </Text>
      </Wrapper>
      <Wrapper pb={24}>
        <Text size="main" align="left" theme={titleCompanyTheme}>
          Новые данные успешно сохранены. Вы можете авторизоваться в сервисах Hyundai
        </Text>
      </Wrapper>
      <Wrapper pb={48}>
        {redirect ? (
          <a rel="noreferrer" href={redirect} className={styles.redirectLink}>
            Войти
          </a>
        ) : (
          <Button fullWidth onClick={() => onStep()}>
            Войти
          </Button>
        )}
      </Wrapper>
    </div>
  );
};

const mapStateToProps = ({ auth }: StoreTypes) => ({
  redirect: auth.forgetPasswordRedirect
});

const mapDispatchToProps = {
  resetStep: forgottenStep
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ForgottenSuccess);
