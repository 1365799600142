import React, { FC, ReactElement, useEffect, useRef } from "react";
import { Button, Input, Wrapper, Checkbox, Text } from "components/ui";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isValidFirstAndLastChar, isValidMMName, isValidCode } from "helpers/validate";
import { connect, ConnectedProps } from "react-redux";
import { StoreTypes } from "store/types";
import NotificationPopup from "components/ui/notification-popup";
import { isChangedValues } from "helpers/formik-handlers";
import styles from "./added-service-form.module.scss";
import history from "../../../../services/history";
import { createServiceRequest, setOpenServiceModalWindow } from "../../../../store/services/actions";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 40 символов")
    .max(40, "Используйте от 1 до 40 символов")
    .test("name", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidMMName, "Используйте кириллицу или латиницу, цифры и символы: ~@#$%^-_(){}'`"),
  code: yup
    .string()
    .required("Необходимо заполнить данные")
    .min(1, "Используйте от 1 до 40 символов")
    .max(40, "Используйте от 1 до 40 символов")
    .test("code", "Не используйте дефис в начале или конце строки", value => isValidFirstAndLastChar(value))
    .matches(isValidCode, "Используйте латиницу, цифры и символ: -"),
  redirect: yup
    .string()
    .required("Необходимо заполнить данные")
    .min(11, "Используйте от 11 до 255 символов")
    .max(255, "Используйте от 11 до 255 символов"),
  redirect_after_restore_password: yup.string().url("Введите валидный url")
});

type ValuesTypes = {
  name: string;
  code: string;
  redirect: string;
  redirect_after_restore_password: string;
  validated_data: boolean;
  enabled_type_send: boolean;
};

const AddedServiceForm: FC<ReduxProps> = ({ onCreate, isOpen, setOpen, isLoading }) => {
  const formikRef = useRef<any>();

  useEffect(() => {
    return (): void => {
      setOpen(false);
    };
  }, []);

  const initValue = {
    name: "",
    code: "",
    redirect: "",
    redirect_after_restore_password: "",
    validated_data: false,
    enabled_type_send: false
  };

  const inputsBlur = (item: string, value: string): void => {
    if (value) {
      formikRef.current.setFieldValue(item, value.trim());
      formikRef.current.setTouched({ [item]: true });
    }
  };

  const guard = (): void => {
    if (Object.keys(formikRef.current.errors).length !== 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const submitHandler = (values: ValuesTypes): void => {
    onCreate(values);
  };

  const notificationTitle = "Данные успешно сохранены";

  return (
    <div className={styles.container}>
      <NotificationPopup
        title={notificationTitle}
        isOpen={isOpen}
        action={() => {
          history.push(`/admin/services`);
          setOpen(false);
        }}
      />
      <Formik
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={submitHandler}
        initialValues={initValue}
      >
        {({ values, touched, setFieldValue, errors, handleChange }): ReactElement => (
          <Form>
            <Wrapper pt={30} pl={40}>
              <h3 className={styles.title}>Добавление сервиса</h3>
              <div className={styles.form_input}>
                <div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="name"
                      placeholder="Обязательное поле"
                      label="Название"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("name", values.name)}
                      value={values.name}
                      invalid={Boolean(errors.name && touched.name)}
                      errorMessages={errors.name && touched.name ? errors.name : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="code"
                      placeholder="Обязательное поле"
                      label="Код сервиса"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("code", values.code)}
                      value={values.code}
                      invalid={Boolean(errors.code && touched.code)}
                      errorMessages={errors.code && touched.code ? errors.code : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="redirect"
                      placeholder="http://localhost"
                      label="Коллбэк url"
                      onChange={handleChange}
                      onBlur={(): void => inputsBlur("redirect", values.redirect)}
                      value={values.redirect}
                      invalid={Boolean(errors.redirect && touched.redirect)}
                      errorMessages={errors.redirect && touched.redirect ? errors.redirect : null}
                    />
                  </div>
                  <div className={styles.inputWrapper}>
                    <Input
                      name="redirect_after_restore_password"
                      placeholder="Введите ссылку для восстановления пароля"
                      label="Редирект после восстановления пароля"
                      onChange={handleChange}
                      onBlur={(): void =>
                        inputsBlur("redirect_after_restore_password", values.redirect_after_restore_password)
                      }
                      value={values.redirect_after_restore_password}
                      invalid={Boolean(
                        errors.redirect_after_restore_password && touched.redirect_after_restore_password
                      )}
                      errorMessages={
                        errors.redirect_after_restore_password && touched.redirect_after_restore_password
                          ? errors.redirect_after_restore_password
                          : null
                      }
                    />
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      invalid={!values.validated_data && !!errors.validated_data && touched.validated_data}
                      errorMessages={
                        !values.validated_data && !!errors.validated_data && touched.validated_data
                          ? errors.validated_data
                          : null
                      }
                      checked={!!values.validated_data}
                      onClick={() => setFieldValue("validated_data", !values.validated_data)}
                      name="validated_data"
                      color="prime"
                    >
                      <Text size="h5">Данные валидированы</Text>
                    </Checkbox>
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      invalid={!values.enabled_type_send && !!errors.enabled_type_send && touched.enabled_type_send}
                      errorMessages={
                        !values.enabled_type_send && !!errors.enabled_type_send && touched.enabled_type_send
                          ? errors.enabled_type_send
                          : null
                      }
                      checked={!!values.enabled_type_send}
                      onClick={() => setFieldValue("enabled_type_send", !values.enabled_type_send)}
                      name="enabled_type_send"
                      color="prime"
                    >
                      <Text size="h5">Выбор типа отправки кода авторизации (sms \ voice)</Text>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </Wrapper>
            <div>
              <Wrapper className={styles.form_btn} pl={40} pb={20}>
                <div>
                  <Button onClick={guard} type="submit" disabled={isChangedValues(initValue, values) || isLoading}>
                    Сохранить
                  </Button>
                </div>
                <div>
                  <Button
                    noBorder
                    theme="transparent"
                    onClick={(): void => history.push("/admin/services")}
                    disabled={isLoading}
                  >
                    Отменить
                  </Button>
                </div>
              </Wrapper>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ services }: StoreTypes) => ({
  isOpen: services.modalWindow,
  isLoading: services.isLoading
});

const mapDispatchToProps = {
  onCreate: createServiceRequest,
  setOpen: setOpenServiceModalWindow
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AddedServiceForm);
