import React from "react";
import { EmployeeDC, RoleType } from "store/employeesDC/types";
import moment from "moment";
import styles from "./employeesDC.module.scss";

const renderRoles = (roles: Array<RoleType>): string | JSX.Element => {
  if (!roles || roles.length === 0) {
    return "";
  }
  return (
    <ul className={styles.roleList}>
      {roles.map(role => (
        <li key={role.dealer_center_id + role.role_id} className={styles.roleItem}>
          {`${role.dealer_center_name} - ${role.role_name} (${role.active ? "Активный" : "Неактивный"})`}
        </li>
      ))}
    </ul>
  );
};

export const columnsDC = [
  {
    title: "ID",
    dataIndex: "id",
    render: ({ id }: EmployeeDC): string => id
  },
  {
    title: "ФИО",
    dataIndex: "name",
    render: ({ name, lastname, patronymic }: EmployeeDC): string =>
      `${lastname} ${(name && name[0]) || ""}. ${(patronymic && patronymic[0]) || ""}${(patronymic && ".") || ""}`
  },
  {
    title: "Роли",
    dataIndex: "role",
    render: ({ roles }: EmployeeDC) => renderRoles(roles)
  },
  {
    title: "Email",
    dataIndex: "email",
    render: ({ email }: EmployeeDC): string => email || ""
  },
  {
    title: "Дата обновления",
    dataIndex: "updatedAt",
    render: ({ updated_at }: EmployeeDC): string => (updated_at ? moment(updated_at).format("DD.MM.YYYY") : "")
  }
];
