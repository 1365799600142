import React, { FC, useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { Text, Wrapper, Input, Button } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import Pagination from "components/ui/pagination/pagination";
import Select from "components/ui/select";
import { StoreTypes } from "store/types";
import { dealerCenterRequest, synchronizeDealerCenterRequest } from "store/dealer-center/actions";
import selectOptions from "constant/quantity-rows-per-admin-page";
import Row from "components/ui/row";
import { useDebounce } from "hooks/useDebounce";
import { dealershipInfo } from "store/dealers/actions";
import { resetErrorAndStatus } from "store/actions";
import NotificationPopup from "components/ui/notification-popup";
import usePermissions from "helpers/usePermissions";
import { superadminPermissions } from "constant/permissions";
import { columns } from "pages/admin/dealers/columns";
import { paginationTheme, inputAdminTheme, buttonAddAndRemoveTheme } from "components/ui/switch-themes";
import styles from "./dealers-page.module.scss";
import Warning from "../../../components/ui/warning";

const DealersPage: FC<ReduxProps> = ({
  status,
  isLoading,
  dealerCenterRequest: getDealers,
  dealerCenters: { data: dealerCenters, last_page: lastPage, last_update: lastUpdate },
  resetErrorsAndStatus,
  synchronizeDealerCenterRequest: synchronizeDealers
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notification, setNotification] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search);

  const isPermissionAdminMain = usePermissions(superadminPermissions);

  useEffect(() => {
    getDealers({
      "pagination[page]": currentPage,
      "pagination[count]": rowsPerPage,
      search: debouncedSearch
    });
  }, [currentPage, rowsPerPage, debouncedSearch]);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, []);

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    if (status?.synchronizeDealerCenter) {
      setNotification(true);
    }
  }, [status]);

  const handleCloseNotification = () => {
    setNotification(false);
    getDealers({
      "pagination[page]": currentPage,
      "pagination[count]": rowsPerPage,
      search: debouncedSearch
    });
  };

  return (
    <>
      <div className={styles.header}>
        <NotificationPopup
          title="Синхронизация прошла успешно"
          isOpen={notification}
          action={handleCloseNotification}
        />
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            Дилерские центры
          </Text>
        </Wrapper>
        {isPermissionAdminMain ? (
          <Row justifyContent="spaceBetween">
            <div className={styles.searchWrapper}>
              <Input
                name="search"
                type="text"
                theme={inputAdminTheme}
                placeholder="Поиск по названию, городу, SAP-коду"
                value={search}
                onChange={changeSearch}
                disabled={isLoading}
              />
            </div>
            <Wrapper pl={20} className={styles.btnWrapper}>
              <Button size="small" onClick={synchronizeDealers} theme={buttonAddAndRemoveTheme} disabled={isLoading}>
                Синхронизация
              </Button>
              <span>
                Дата последней синхронизации:
                <span className={styles.lastUpdate}>
                  {lastUpdate ? moment(lastUpdate).format("DD.MM.YYYY HH:mm") : ""}
                </span>
              </span>
            </Wrapper>
          </Row>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <Loading isLoading={isLoading} />
        {!isLoading && <Table columns={columns} data={dealerCenters} />}
        {!isLoading && dealerCenters.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ dealerCenter }: StoreTypes) => ({
  isLoading: dealerCenter.isLoading,
  dealerCenters: dealerCenter.dealerCenters,
  status: dealerCenter.status
});

const mapDispatchToProps = {
  dealershipInfo,
  dealerCenterRequest,
  resetErrorsAndStatus: resetErrorAndStatus,
  synchronizeDealerCenterRequest
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(DealersPage);
