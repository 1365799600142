import { AuthEmailTypes, AuthLoginTypes, AuthPasswordTypes } from "services/api/authentication ";
import { RegisterPhone, RegisterUserTypes } from "services/api/register";
import { STEP_FORGOTTEN, STEP_REGISTER, STEP_SIGN_IN } from "constant/step-auth";
import { ForgottenRestoreTypes } from "services/api/forgotten";
import { OPEN_AUTH_STATUS } from "constant/api";
import {
  AuthAddedPhoneTypes,
  AuthData,
  AuthSSOType,
  ForgottenCheckedLinkTypes,
  OAuthParams,
  PasswordEmail
} from "./types";
import { TYPES } from "./constants";

export const authLoad = () => ({ type: TYPES.AUTH_LOAD });

export const authLoadStop = () => ({ type: TYPES.AUTH_LOAD_STOP });

export const authRequestLogin = (login: AuthLoginTypes) => ({
  type: TYPES.AUTH_REQUEST_LOGIN,
  payload: login
});

export const authRequestPassword = (payload: AuthEmailTypes) => ({
  type: TYPES.AUTH_REQUEST_PASSWORD,
  payload
});

export const authRequestCode = (code: string) => ({
  type: TYPES.AUTH_REQUEST_CODE,
  payload: code
});

export const preAuthRequest = (token: string) => ({
  type: TYPES.PRE_AUTH_REQUEST,
  payload: { token }
});

export const preAuthSuccess = () => ({
  type: TYPES.PRE_AUTH_SUCCESS
});

export const preAuthSetLink = (link: string) => ({
  type: TYPES.PRE_AUTH_SET_LINK,
  payload: link
});

export const authSuccess = () => ({
  type: TYPES.AUTH_SUCCESS
});

export const authEmailContainsCheck = (email: string) => ({
  type: TYPES.AUTH_EMAIL_CONTAINS_CHECK,
  payload: email
});

export const authUpdateStepSignIn = (step: STEP_SIGN_IN) => ({
  type: TYPES.AUTH_UPDATE_STEP_SIGN_IN,
  payload: step
});

export const authError = (error: { [key: string]: string }) => ({
  type: TYPES.AUTH_ERROR,
  payload: error
});
export const authStatus = (status: string) => ({
  type: TYPES.AUTH_STATUS,
  payload: status
});

export const serverError = (error: string | null) => ({
  type: TYPES.SERVER_ERROR,
  payload: error
});

export const authDataUpdate = (data: Partial<AuthData>) => ({
  type: TYPES.AUTH_UPDATE_DATA,
  payload: data
});

export const authResetAll = () => ({
  type: TYPES.AUTH_RESET_ALL
});

export const authReset = () => ({
  type: TYPES.AUTH_RESET
});

export const authAddedPhone = (data: AuthAddedPhoneTypes) => ({
  type: TYPES.AUTH_ADDED_PHONE,
  payload: data
});

export const authAddedPhoneCode = (code: string | null) => ({
  type: TYPES.AUTH_ADDED_PHONE_CODE,
  payload: code
});

export const authUpdateTtl = (ttl: string | null) => ({
  type: TYPES.AUTH_ADDED_TTL,
  payload: ttl
});

export const isTokenWritten = (status: boolean) => ({
  type: TYPES.AUTH_TOKEN_WRITTEN,
  payload: status
});

export const registerSendSmsPhone = (phone: RegisterPhone) => ({
  type: TYPES.REGISTER_SEND_SMS_PHONE,
  payload: phone
});

export const updateStepRegister = (step: STEP_REGISTER) => ({
  type: TYPES.UPDATE_STEP_REGISTER,
  payload: step
});

export const registerRequestCode = (code: string) => ({
  type: TYPES.REGISTER_REQUEST_CODE,
  payload: code
});

export const registerFormUser = (form: RegisterUserTypes) => ({
  type: TYPES.REGISTER_FORM_USER,
  payload: form
});

export const registerSuccess = () => ({
  type: TYPES.REGISTER_SUCCESS
});

export const forgottenStep = (step: STEP_FORGOTTEN) => ({
  type: TYPES.FORGOTTEN_STEPS,
  payload: step
});

export const forgottenLoginAction = (login: ForgottenRestoreTypes) => ({
  type: TYPES.FORGOTTEN_LOGIN,
  payload: login
});

export const forgottenCodeAction = (code: string) => ({
  type: TYPES.FORGOTTEN_CODE,
  payload: code
});

export const forgottenNewPasswordAction = (data: PasswordEmail) => ({
  type: TYPES.FORGOTTEN_NEW_PASSWORD,
  payload: data
});

export const forgottenCheckingLinkAction = (data: ForgottenCheckedLinkTypes) => ({
  type: TYPES.FORGOTTEN_NEW_CHECKING_LINK,
  payload: data
});

export const checkDataUserRequest = () => ({
  type: TYPES.CHECK_DATA_USER
});

export const openAuthRequest = (params: OAuthParams) => ({
  type: TYPES.OPEN_AUTH_REQUEST,
  payload: params
});

export const openAuthRequestIframe = (params: OAuthParams) => ({
  type: TYPES.OPEN_AUTH_REQUEST_IFRAME,
  payload: params
});

export const openAuthUpdateStatus = (status: OPEN_AUTH_STATUS | null) => ({
  type: TYPES.OPEN_AUTH_UPDATE_STATUS,
  payload: status
});

export const openAuthUpdateUrl = (url: string) => ({
  type: TYPES.OPEN_AUTH_UPDATE_URL,
  payload: url
});

export const verificationEmail = (url: string) => ({
  type: TYPES.VERIFY_EMAIL,
  payload: url
});

export const sendVerifyEmail = () => ({
  type: TYPES.SEND_VERIFY_EMAIL
});

export const setVerify = (state: boolean) => ({
  type: TYPES.SET_VERIFY,
  payload: state
});

export const updateDataUser = (arg: boolean) => ({
  type: TYPES.UPDATE_DATA_USER,
  payload: arg
});

export const getLinksSSO = () => ({
  type: TYPES.GET_LINKS_SSO
});

export const setLinkSSO = (data: any) => ({
  type: TYPES.SET_LINKS_SSO,
  payload: data
});

export const authSSO = (tokens: AuthSSOType) => ({
  type: TYPES.AUTH_SSO,
  payload: tokens
});

export const regSSO = (userID: string) => ({
  type: TYPES.REG_SSO,
  payload: userID
});

export const authRegisterSSO = (arg: boolean) => ({
  type: TYPES.AUTH_REGISTER_SSO,
  payload: arg
});

export const isPhoneRequiredSSO = (arg: boolean) => ({
  type: TYPES.PHONE_REQUIRED_SSO,
  payload: arg
});

export const dataUserSSO = (data: any) => ({
  type: TYPES.DATA_USER_SSO,
  payload: data
});

export const logOutUser = () => ({
  type: TYPES.AUTH_LOGOUT
});

export const getUserSessionToken = () => ({
  type: TYPES.USER_SESSION_TOKEN
});

export const signInOnUserRequest = (id: string) => ({
  type: TYPES.AUTH_ON_USER_REQUEST,
  payload: id
});

export const signInOnUserSuccess = (id: string) => ({
  type: TYPES.AUTH_ON_USER_SUCCESS,
  payload: id
});

export const signOutOnUserRequest = () => ({
  type: TYPES.SIGN_OUT_ON_USER_REQUEST
});

export const forgottenRedirect = (redirect: string) => ({
  type: TYPES.FORGOTTEN_PASSWORD_REDIRECT,
  payload: redirect
});
