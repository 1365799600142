export const TYPES = {
  AUTH_LOAD: "AUTH_LOAD",
  AUTH_LOAD_STOP: "AUTH_LOAD_STOP",
  AUTH_REQUEST_LOGIN: "AUTH_REQUEST_LOGIN",
  AUTH_REQUEST_PASSWORD: "AUTH_REQUEST_PASSWORD",
  AUTH_REQUEST_CODE: "AUTH_REQUEST_CODE",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_ERROR: "AUTH_ERROR",
  AUTH_STATUS: "AUTH_STATUS",

  AUTH_EMAIL_CONTAINS_CHECK: "AUTH_EMAIL_CONTAINS_CHECK",

  PRE_AUTH_REQUEST: "PRE_AUTH_REQUEST",
  PRE_AUTH_SUCCESS: "PRE_AUTH_SUCCESS",
  PRE_AUTH_SET_LINK: "PRE_AUTH_SET_LINK",

  AUTH_RESET_ALL: "AUTH_RESET_ALL",
  AUTH_RESET: "AUTH_RESET",
  AUTH_LOGOUT: "AUTH_LOGOUT",
  AUTH_TOKEN_WRITTEN: "AUTH_TOKEN_WRITTEN",

  AUTH_UPDATE_STEP_SIGN_IN: "AUTH_UPDATE_STEP_SIGN_IN",
  AUTH_UPDATE_DATA: "AUTH_UPDATE_DATA",
  AUTH_ADDED_PHONE: "AUTH_ADDED_PHONE",
  AUTH_ADDED_TTL: "AUTH_ADDED_TTL",
  AUTH_ADDED_PHONE_CODE: "AUTH_ADDED_PHONE_CODE",

  REGISTER_SEND_SMS_PHONE: "REGISTER_SEND_SMS_PHONE",
  UPDATE_STEP_REGISTER: "UPDATE_STEP_REGISTER",
  REGISTER_REQUEST_CODE: "REGISTER_REQUEST_CODE",
  REGISTER_FORM_USER: "REGISTER_FORM_USER",
  SERVER_ERROR: "SERVER_ERROR",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  UPDATE_DATA_USER: "UPDATE_DATA_USER",
  CHECK_DATA_USER: "CHECK_DATA_USER",

  FORGOTTEN_STEPS: "FORGOTTEN_STEPS",
  FORGOTTEN_LOGIN: "FORGOTTEN_LOGIN",
  FORGOTTEN_CODE: "FORGOTTEN_CODE",
  FORGOTTEN_NEW_CHECKING_LINK: "FORGOTTEN_NEW_CHECKING_LINK",
  FORGOTTEN_NEW_PASSWORD: "FORGOTTEN_NEW_PASSWORD",
  FORGOTTEN_PASSWORD_REDIRECT: "FORGOTTEN_PASSWORD_REDIRECT",

  OPEN_AUTH_REQUEST: "OPEN_AUTH_REQUEST",
  OPEN_AUTH_REQUEST_IFRAME: "OPEN_AUTH_REQUEST_IFRAME",
  OPEN_AUTH_UPDATE_STATUS: "OPEN_AUTH_UPDATE_STATUS",
  OPEN_AUTH_UPDATE_URL: "OPEN_AUTH_UPDATE_URL",
  GET_LINKS_SSO: "GET_LINKS_SSO",
  SET_LINKS_SSO: "SET_LINKS_SSO",
  AUTH_SSO: "AUTH_SSO",
  REG_SSO: "REG_SSO",
  AUTH_REGISTER_SSO: "AUTH_REGISTER_SSO",
  PHONE_REQUIRED_SSO: "PHONE_REQUIRED_SSO",
  DATA_USER_SSO: "DATA_USER_SSO",

  VERIFY_EMAIL: "VERIFY_EMAIL",
  SEND_VERIFY_EMAIL: "SEND_VERIFY_EMAIL",
  SET_VERIFY: "SET_VERIFY",
  USER_SESSION_TOKEN: "USER_SESSION_TOKEN",

  AUTH_ON_USER_REQUEST: "AUTH_ON_USER_REQUEST",
  AUTH_ON_USER_SUCCESS: "AUTH_ON_USER_SUCCESS",
  SIGN_OUT_ON_USER_REQUEST: "SIGN_OUT_ON_USER_REQUEST"
} as const;
