import axios from "axios";
import { GetDealerCenterParams, DealerCenterType, DealerCenter, SynchronizeDateType } from "store/dealer-center/types";

export const DealerCenterApi = {
  getDealerCenter: (params?: Partial<GetDealerCenterParams>): Promise<DealerCenterType> =>
    axios.get("/front/dealer", { params }),
  synchronizeDealerCenter: (): Promise<SynchronizeDateType> => axios.post("/front/dealer/synchronize"),
  deleteDealerCenter: (id: number): Promise<void> => axios.delete(`/front/team/${id}`),
  getDealerCenterById: (id: number | string): Promise<DealerCenter> => axios.get(`/front/team/${id}`)
};
