import { TYPES_EMPLOYEES_DC } from "store/employeesDC/constants";
import { RESET_ERROR_AND_STATUS } from "store/constants";
import { ActionsType, Store } from "./types";

const initialState: Store = {
  isLoading: false,
  error: null,
  employeesDC: {
    current_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    data: []
  }
};

export const employeesDCReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_EMPLOYEES_DC.EMPLOYEES_DC_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_EMPLOYEES_DC.EMPLOYEES_DC_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null };
    }
    case TYPES_EMPLOYEES_DC.EMPLOYEES_DC_SUCCESS: {
      return { ...state, employeesDC: action.payload };
    }
    case TYPES_EMPLOYEES_DC.EMPLOYEES_DC_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }

    default: {
      return state;
    }
  }
};
