import React, { FC, ReactFragment } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import usePermissions from "helpers/usePermissions";
import { superadminPermissions } from "constant/permissions";
import mainTheme, { isHyundaiTheme, nameCompanyDivision } from "components/ui/switch-themes";
import styles from "./nav.module.scss";

type Link = {
  title: string;
  id: number;
  link: string;
  permission?: boolean;
  children?: Array<ChildrenTabs>;
};
type ChildrenTabs = {
  title: string;
  id: number;
  link: string;
  permission?: boolean;
};

const Nav: FC = () => {
  const isAdminMain = usePermissions(superadminPermissions);

  const menu = [
    {
      title: "Сотрудники",
      id: 1,
      link: "/admin/employees",
      permission: isAdminMain,
      children: [
        {
          id: 11,
          title: "Сотрудники дилерских центров",
          link: "/admin/employees/dc",
          permission: isAdminMain && isHyundaiTheme
        },
        {
          id: 12,
          title: `Сотрудники ${nameCompanyDivision}`,
          link: "/admin/employees/main",
          permission: isAdminMain
        }
      ]
    },
    {
      title: "Пользователи",
      id: 2,
      link: "/admin/users",
      permission: isAdminMain
    },
    {
      title: "Дилерские центры",
      id: 3,
      link: "/admin/dealers",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Юридические лица",
      id: 5,
      link: "/admin/legal",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Маркетинговые рассылки",
      id: 4,
      link: "/admin/marketing-mailings",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Заявки на удаление ПД",
      id: 6,
      link: "/admin/delete-personal-data-orders",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Удаленные ПД",
      id: 7,
      link: "/admin/deleted-personal-data",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Сервисы",
      id: 8,
      link: "/admin/services",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Третьи лица",
      id: 9,
      link: "/admin/third-parties",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "Категории ПДн",
      id: 10,
      link: "/admin/personal-data-list",
      permission: isAdminMain && isHyundaiTheme
    },
    {
      title: "ЧС телефонов",
      id: 13,
      link: "/admin/phone-blacklist",
      permission: isAdminMain && isHyundaiTheme
    }
  ];

  const cnChildren = cn(styles.sub, { [styles[mainTheme]]: mainTheme });
  const cnLink = cn(styles.link, {
    [styles[mainTheme]]: mainTheme
  });
  const cnParent = cn(styles.parent, { [styles[mainTheme]]: mainTheme });
  const cnItem = cn(styles.item, { [styles[mainTheme]]: mainTheme });

  const itemNav = (arr: Array<Link>, level = 0): ReactFragment => {
    const tabs = arr.filter((tab: Link): boolean => tab.permission === true);
    return tabs.map(item => {
      if (item.children && item.children.length > 0) {
        const tabsEmployee = item.children.filter((tab: Link): boolean => tab.permission === true);

        return (
          <li key={item.id} className={cnItem}>
            <NavLink
              exact
              className={cn([styles.link, cnParent])}
              isActive={(match, location): boolean => {
                return location.pathname.includes(item.link);
              }}
              activeClassName={styles.linkActive}
              to={item.link}
            >
              {item.title}
            </NavLink>

            <ul className={cnChildren}>{itemNav(tabsEmployee, level + 1)}</ul>
          </li>
        );
      }
      return (
        <li key={item.id} className={cnItem}>
          <NavLink className={cnLink} activeClassName={styles.linkActive} to={item.link}>
            {item.title}
          </NavLink>
        </li>
      );
    });
  };

  return <ul className={styles.nav}>{itemNav(menu)}</ul>;
};

export default Nav;
