import { TYPES_EMPLOYEES_DC } from "store/employeesDC/constants";
import { GetEmployeesDCParams, EmployeesDCType } from "./types";

export const employeesDCLoad = () => ({
  type: TYPES_EMPLOYEES_DC.EMPLOYEES_DC_LOAD
});
export const employeesDCLoadStop = () => ({
  type: TYPES_EMPLOYEES_DC.EMPLOYEES_DC_LOAD_STOP
});

export const employeesDCRequest = (body: GetEmployeesDCParams) => ({
  type: TYPES_EMPLOYEES_DC.EMPLOYEES_DC_REQUEST,
  payload: body
});

export const employeesDCSuccess = (payload: EmployeesDCType) => ({
  type: TYPES_EMPLOYEES_DC.EMPLOYEES_DC_SUCCESS,
  payload
});

export const employeesDCError = (error: { [key: string]: string }) => ({
  type: TYPES_EMPLOYEES_DC.EMPLOYEES_DC_ERROR,
  payload: error
});
