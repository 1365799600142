import axios from "axios";
import { LoginTypes } from "./authentication ";

export interface ForgottenRestoreTypes {
  phone?: string;
  username: string;
  email?: string;
  type?: LoginTypes;
}

export interface ForgottenCodeTypes extends ForgottenRestoreTypes {
  code: string;
}
export interface ForgottenResetTypes {
  phone?: string;
  code?: string;
  type: LoginTypes;
  password: string | number;
  email?: string;
  token?: string;
}

interface ForgottenPhoneTypes {
  code?: number;
  status: string;
}

type ForgottenTypes = Record<"message" | "status", string>;

type ForgottenCheckedLink = Record<"email" | "token" | "type", string>;

type ForgottenResetEmail = {
  email: string;
  phone: string;
  password: string | number;
  type: LoginTypes;
};

type ForgottenResetPhone = {
  code: string;
  token: string;
  password: string | number;
  type: LoginTypes;
};

export interface ForgottenPasswordTypes {
  message: string;
  redirect?: string;
}

export const Forgotten = {
  restore: (body: ForgottenRestoreTypes): Promise<ForgottenPhoneTypes> => {
    return axios.post("/front/restore_password", body);
  },
  checked: (body: ForgottenCheckedLink): Promise<ForgottenTypes> => {
    return axios.post("/front/restore_password/check", body);
  },
  code: (body: ForgottenCodeTypes): Promise<ForgottenTypes> => {
    return axios.post("/front/check-code", body);
  },
  reset: (body: any): Promise<ForgottenPasswordTypes> => {
    return axios.post("/front/restore_password/reset", body);
  }
};
