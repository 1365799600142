import React, { FC, ReactElement, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import NotificationPopup from "components/ui/notification-popup";
import { Input, Text, Wrapper, Button, Checkbox } from "components/ui";
import history from "services/history";
import {
  buttonAddAndRemoveTheme,
  isHyundaiTheme,
  sizeTitleCreateUsers,
  inputAdminTheme,
  paddingCreateUser
} from "components/ui/switch-themes";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./EditService.module.scss";
import trimValueOnBlur from "../../../helpers/trimValueOnBlur";
import validationSchema from "./validationSchema";
import {
  closeServiceSuccessModal,
  getPermissionListRequest,
  getPersonalDataTypeRequest,
  getServiceByIdRequest,
  updateServiceRequest,
  getThirdPartiesListRequest
} from "../../../store/services/actions";
import { PersonalData } from "../../../store/services/types";
import { StoreTypes } from "../../../store/types";
import { Loading } from "../../../components/ui/loading";
import Row from "../../../components/ui/row";
import SortableItem from "./SortableItem";

const personalDataTypeListConstant = [
  { id: 1, name: "Общие сведения" },
  { id: 2, name: "Паспорт" },
  { id: 3, name: "Место жительства" },
  { id: 4, name: "Водительское удостоверение" }
];

const toggleById = (array: Array<string | number>, id: string | number) => {
  const newArray = [];
  let isContain = false;
  array.forEach(item => {
    if (item !== id) {
      newArray.push(item);
    } else {
      isContain = true;
    }
  });
  if (!isContain) {
    newArray.push(id);
  }
  return newArray;
};

const EditService: FC = () => {
  const { id: serviceId } = useParams<any>();
  const {
    currentService,
    permissionList,
    isLoading,
    personalDataList,
    isSuccessModalOpen,
    thirdPartiesList
  } = useSelector((state: StoreTypes) => state.services);
  const dispatch = useDispatch();

  const [pdListUserSortable, setPdListUserSortable] = useState<Array<PersonalData>>([]);
  const [pdListTenantSortable, setPdListTenantSortable] = useState<Array<PersonalData>>([]);
  const [isChangeOrder, setIsChangeOrder] = useState<boolean>(false);

  useEffect(() => {
    const sortPersonalDataList = (initialArray: PersonalData[], orderValues?: PersonalData[]) => {
      if (orderValues && orderValues.length > 0) {
        const orderIds = new Set(orderValues.map(item => item.id));
        const filteredInitialArray = initialArray
          .filter(item => !orderIds.has(item.id))
          .map(el => ({ id: el.id, name: el.name }));
        return orderValues.concat(filteredInitialArray);
      }
      return initialArray;
    };

    setPdListUserSortable(sortPersonalDataList(personalDataList, currentService?.personalDataListUser));
    if (currentService?.code === "hyundai-mobility") {
      setPdListTenantSortable(sortPersonalDataList(personalDataList, currentService?.personalDataListTenant));
    }
  }, [personalDataList, currentService]);

  useEffect(() => {
    if (serviceId) {
      dispatch(getServiceByIdRequest(serviceId));
      dispatch(getPersonalDataTypeRequest());
      dispatch(getPermissionListRequest());
      dispatch(getThirdPartiesListRequest());
    }
  }, [serviceId]);

  const createSortedValues = (
    values: Array<number | string>,
    listSortable: Array<PersonalData>
  ): Array<number | string> => {
    const uniqValues = new Set(values);
    const sortedValues: Array<number | string> = [];
    if (uniqValues.size > 0) {
      listSortable.forEach(item => {
        if (uniqValues.has(item.id)) {
          sortedValues.push(item.id);
        }
      });
    }
    return sortedValues;
  };

  const submitHandler = async (values: any) => {
    const editedService = values;
    editedService.personalDataListUser = createSortedValues(values.personalDataListUser, pdListUserSortable);
    if (currentService?.code === "hyundai-mobility") {
      editedService.personalDataListTenant = createSortedValues(values.personalDataListTenant, pdListTenantSortable);
    }
    dispatch(updateServiceRequest(serviceId, editedService));
  };

  const handleDragEndUser = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setPdListUserSortable(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
      if (!isChangeOrder && currentService && currentService.personalDataListUser.length > 0) {
        setIsChangeOrder(true);
      }
    }
  };

  const handleDragEndTenant = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setPdListTenantSortable(items => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
      if (!isChangeOrder && currentService && currentService.personalDataListTenant.length > 0) {
        setIsChangeOrder(true);
      }
    }
  };

  if (isLoading || !currentService) {
    return (
      <Loading isLoading>
        <Wrapper pt={300}>
          <Row justifyContent="justifyCenter" alignItems="alignCenter">
            <h1>Идет загрузка</h1>
          </Row>
        </Wrapper>
      </Loading>
    );
  }
  return (
    <div className={styles.wrapper}>
      <NotificationPopup
        title="Данные успешно сохранены"
        isOpen={isSuccessModalOpen}
        action={() => {
          dispatch(closeServiceSuccessModal());
          history.push("/admin/services");
        }}
      />
      <Formik
        initialValues={{
          name: currentService.name,
          redirect: currentService.redirect,
          redirect_after_restore_password: currentService.redirect_after_restore_password,
          validated_data: currentService.validated_data,
          personalDataListUser: currentService.personalDataListUser.map(item => item.id),
          personalDataListTenant: currentService.personalDataListTenant.map(item => item.id),
          personalDataTypeList: currentService.personalDataTypeList,
          permissions: currentService.permissions.map(item => item.id),
          thirdParties: currentService.thirdParties.map(item => item.id),
          enabled_type_send: currentService.enabled_type_send
        }}
        onSubmit={submitHandler}
        validateOnBlur={false}
        validateOnChange
        validationSchema={validationSchema()}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          isValid,
          handleChange,
          setFieldTouched,
          submitForm,
          dirty
        }): ReactElement => (
          <Form>
            <div className={styles.wrapper}>
              <div className={styles.block1}>
                <div>
                  <Wrapper pt={48} pb={24}>
                    <Text uppercase={!isHyundaiTheme} size={sizeTitleCreateUsers} theme="black">
                      Редактирование сервиса
                    </Text>
                  </Wrapper>
                </div>
                <div className={styles.wrap}>
                  <div className={styles.blockColumn}>
                    <Wrapper pb={paddingCreateUser}>
                      <Input
                        name="name"
                        label="Название"
                        type="text"
                        theme={inputAdminTheme}
                        placeholder="Название"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={(): void => {
                          trimValueOnBlur("name", values.name, setFieldValue, setFieldTouched);
                        }}
                        errorMessages={(touched.name && errors.name) || (values.name && errors.name)}
                        invalid={Boolean(errors.name && touched.name)}
                      />
                    </Wrapper>
                  </div>
                </div>
                <div className={styles.wrap}>
                  <div className={styles.blockColumn}>
                    <Wrapper pb={paddingCreateUser}>
                      <Input
                        name="redirect"
                        label="Коллбэк url"
                        type="text"
                        theme={inputAdminTheme}
                        placeholder="http://localhost"
                        value={values.redirect}
                        onChange={handleChange}
                        onBlur={(): void => {
                          trimValueOnBlur("redirect", values.redirect, setFieldValue, setFieldTouched);
                        }}
                        errorMessages={(touched.redirect && errors.redirect) || (values.redirect && errors.redirect)}
                        invalid={Boolean(errors.redirect && touched.redirect)}
                      />
                    </Wrapper>
                  </div>
                </div>
                <div className={styles.wrap}>
                  <div className={styles.blockColumn}>
                    <Wrapper pb={paddingCreateUser}>
                      <Input
                        name="redirect_after_restore_password"
                        label="Редирект после восстановления пароля"
                        type="text"
                        theme={inputAdminTheme}
                        placeholder="Введите ссылку для восстановления пароля"
                        value={values.redirect_after_restore_password ?? ""}
                        onChange={handleChange}
                        onBlur={(): void => {
                          trimValueOnBlur(
                            "redirect_after_restore_password",
                            values.redirect_after_restore_password ?? "",
                            setFieldValue,
                            setFieldTouched
                          );
                        }}
                        errorMessages={
                          (touched.redirect_after_restore_password && errors.redirect_after_restore_password) ||
                          (values.redirect_after_restore_password && errors.redirect_after_restore_password)
                        }
                        invalid={Boolean(
                          errors.redirect_after_restore_password && touched.redirect_after_restore_password
                        )}
                      />
                    </Wrapper>
                  </div>
                </div>
                <div className={styles.wrap}>
                  <div>
                    <Wrapper pb={paddingCreateUser}>
                      <Checkbox
                        invalid={!values.validated_data && !!errors.validated_data && touched.validated_data}
                        errorMessages={
                          !values.validated_data && !!errors.validated_data && touched.validated_data
                            ? errors.validated_data
                            : null
                        }
                        checked={!!values.validated_data}
                        onClick={() => setFieldValue("validated_data", !values.validated_data)}
                        name="validated_data"
                        color="prime"
                      >
                        <Text size="h5"> Данные валидированы</Text>
                      </Checkbox>
                    </Wrapper>
                  </div>
                </div>
                <div className={styles.wrap}>
                  <div>
                    <Wrapper pb={paddingCreateUser}>
                      <Checkbox
                        invalid={!values.enabled_type_send && !!errors.enabled_type_send && touched.enabled_type_send}
                        errorMessages={
                          !values.enabled_type_send && !!errors.enabled_type_send && touched.enabled_type_send
                            ? errors.enabled_type_send
                            : null
                        }
                        checked={!!values.enabled_type_send}
                        onClick={() => setFieldValue("enabled_type_send", !values.enabled_type_send)}
                        name="enabled_type_send"
                        color="prime"
                      >
                        <Text size="h5">Выбор типа отправки кода авторизации (sms \ voice)</Text>
                      </Checkbox>
                    </Wrapper>
                  </div>
                </div>
                {permissionList.length ? (
                  <div>
                    <Wrapper pt={32} pb={24}>
                      <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
                        Доступы к методам API
                      </Text>
                    </Wrapper>
                  </div>
                ) : null}

                {permissionList.map(permission => (
                  <Wrapper key={permission.id} pb={16} className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={!!values.permissions.find(pd => pd === permission.id)}
                      name="p2"
                      color="prime"
                      onClick={() => setFieldValue("permissions", toggleById(values.permissions, permission.id))}
                    >
                      {permission.display_name}
                    </Checkbox>
                  </Wrapper>
                ))}
                <div>
                  <Wrapper pt={32} pb={24}>
                    <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
                      Доступ к персональным данным для других сервисов
                    </Text>
                  </Wrapper>
                </div>

                {personalDataTypeListConstant.map(personalData => (
                  <Wrapper key={personalData.id} pb={16} className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={!!values.personalDataTypeList.find(pd => pd === personalData.id)}
                      name="p2"
                      color="prime"
                      onClick={() =>
                        setFieldValue("personalDataTypeList", toggleById(values.personalDataTypeList, personalData.id))
                      }
                    >
                      {personalData.name}
                    </Checkbox>
                  </Wrapper>
                ))}
                {thirdPartiesList.length > 0 && (
                  <>
                    <div>
                      <Wrapper pt={32} pb={24}>
                        <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
                          Третьи лица
                        </Text>
                      </Wrapper>
                    </div>
                    {thirdPartiesList.map(thirdParty => (
                      <Wrapper key={thirdParty.id} pb={16} className={styles.checkboxWrapper}>
                        <Checkbox
                          checked={!!values.thirdParties.find(item => item === thirdParty.id)}
                          name="p2"
                          color="prime"
                          onClick={() => setFieldValue("thirdParties", toggleById(values.thirdParties, thirdParty.id))}
                        >
                          {`${thirdParty.name} (${thirdParty.email})`}
                        </Checkbox>
                      </Wrapper>
                    ))}
                  </>
                )}
                <div>
                  <Wrapper pt={32} pb={24}>
                    <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
                      Категории персональных данных (Пользователь)
                    </Text>
                  </Wrapper>
                </div>
                <DndContext onDragEnd={handleDragEndUser} modifiers={[restrictToVerticalAxis]}>
                  <SortableContext items={pdListUserSortable}>
                    {pdListUserSortable.length > 0 &&
                      pdListUserSortable.map(personalData => (
                        <SortableItem key={personalData.id} personalData={personalData}>
                          <Wrapper pt={10} pl={10}>
                            <Checkbox
                              checked={!!values.personalDataListUser.find(pd => pd === personalData.id)}
                              name="p2"
                              color="prime"
                              onClick={() =>
                                setFieldValue(
                                  "personalDataListUser",
                                  toggleById(values.personalDataListUser, personalData.id)
                                )
                              }
                            >
                              {personalData.name}
                            </Checkbox>
                          </Wrapper>
                        </SortableItem>
                      ))}
                  </SortableContext>
                </DndContext>

                {currentService.code === "hyundai-mobility" ? (
                  <>
                    <div>
                      <Wrapper pt={32} pb={24}>
                        <Text uppercase={!isHyundaiTheme} size="h5" theme="black">
                          Категории персональных данных (Арендатор)
                        </Text>
                      </Wrapper>
                    </div>
                    <DndContext onDragEnd={handleDragEndTenant} modifiers={[restrictToVerticalAxis]}>
                      <SortableContext items={pdListTenantSortable}>
                        {pdListTenantSortable.map(personalData => (
                          <SortableItem key={personalData.id} personalData={personalData}>
                            <Wrapper pt={10} pl={10}>
                              <Checkbox
                                checked={!!values.personalDataListTenant.find(pd => pd === personalData.id)}
                                name="p2"
                                color="prime"
                                onClick={() =>
                                  setFieldValue(
                                    "personalDataListTenant",
                                    toggleById(values.personalDataListTenant, personalData.id)
                                  )
                                }
                              >
                                {personalData.name}
                              </Checkbox>
                            </Wrapper>
                          </SortableItem>
                        ))}
                      </SortableContext>
                    </DndContext>
                  </>
                ) : null}
              </div>
              <div className={styles.block2}>
                <Wrapper pt={32} pb={16}>
                  <div className={styles.btnBlock}>
                    <Button
                      theme={buttonAddAndRemoveTheme}
                      type="submit"
                      disabled={!(dirty || isChangeOrder) || isLoading}
                    >
                      Сохранить
                    </Button>
                    <Button
                      theme="white"
                      onClick={(): void => {
                        history.push("/admin/services");
                      }}
                      noBorder
                      disabled={isLoading}
                    >
                      Отменить
                    </Button>
                  </div>
                </Wrapper>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditService;
