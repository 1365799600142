import axios from "axios";
import { GetEmployeesParams, EmployeesType } from "store/employees/types";
import { GetEmployeesDCParams, RoleDCType, EmployeesDCType } from "store/employeesDC/types";

type Position = {
  search: string;
  type: string;
};

export type ResponsePosition = {
  id: number;
  title: string;
  title_en: string;
  lms_id: string | number;
  type: string | number;
};

// eslint-disable-next-line import/prefer-default-export
export const Employees = {
  getEmployees: (body: Partial<GetEmployeesParams>): Promise<EmployeesType> => axios.post(`/front/employee`, body),
  getPosition: (body: Partial<Position>): Promise<ResponsePosition[]> => axios.post(`/front/position`, body),
  getEmployeesDC: (params?: Partial<GetEmployeesDCParams>): Promise<EmployeesDCType> =>
    axios.get(`/front/dealer/user`, { params }),
  getRolesDC: (): Promise<RoleDCType[]> => axios.get(`/front/dealer/roles`)
};
