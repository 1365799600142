import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, Wrapper, Input } from "components/ui";
import { Table } from "components/ui/table";
import { Loading } from "components/ui/loading";
import { employeesDCRequest } from "store/employeesDC/actions";
import { resetErrorAndStatus } from "store/actions";
import { StoreTypes } from "store/types";
import Warning from "components/ui/warning";
import { paginationTheme, inputAdminTheme } from "components/ui/switch-themes";
import { useDebounce } from "hooks/useDebounce";
import { columnsDC } from "./columns";
import Select from "../../../components/ui/select";
import Pagination from "../../../components/ui/pagination/pagination";
import TeamName from "../../../constant/team-name";
import selectOptions from "../../../constant/quantity-rows-per-admin-page";
import Row from "../../../components/ui/row";
import styles from "./employeesDC.module.scss";

type Props = {
  title: string;
  teamName: TeamName;
};

const EmployeesDC: FC<ReduxProps & Props> = ({
  employeesDC: { data: employeesDC, last_page: lastPage },
  getEmployeesDC,
  isLoading,
  title,
  resetErrorsAndStatus
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    return (): void => {
      resetErrorsAndStatus();
    };
  }, []);

  useEffect(() => {
    if (!debouncedSearch || debouncedSearch.length >= 3) {
      getEmployeesDC({
        "pagination[page]": currentPage,
        "pagination[count]": rowsPerPage,
        search: debouncedSearch
      });
    }
  }, [currentPage, rowsPerPage, debouncedSearch]);

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className={styles.header}>
        <Wrapper pb={20}>
          <Text size="h3" theme="black">
            {title}
          </Text>
        </Wrapper>
        <Row justifyContent="spaceBetween">
          <div className={styles.searchWrapper}>
            <Input
              name="search"
              type="text"
              theme={inputAdminTheme}
              placeholder="Поиск по ID, email, ФИО"
              value={search}
              onChange={changeSearch}
              disabled={isLoading}
              errorMessages={
                debouncedSearch.length !== 0 && debouncedSearch.length < 3
                  ? "Минимальное количество символов для поиска - 3"
                  : null
              }
            />
          </div>
        </Row>
      </div>
      <div className={styles.wrapper}>
        <Wrapper pt={26}>
          <Loading isLoading={isLoading} />
          {!isLoading && <Table columns={columnsDC} data={employeesDC} />}
        </Wrapper>
        {!isLoading && employeesDC.length === 0 && (
          <Wrapper pt={16}>
            <Warning color="black">Ничего не найдено</Warning>
          </Wrapper>
        )}
        <Wrapper pt={32}>
          <div className={styles.bottomBlock}>
            <div />
            <Pagination
              theme={paginationTheme}
              onChange={setCurrentPage}
              currentPage={currentPage}
              totalPages={lastPage}
            />
            <Select options={selectOptions} onClick={setRowsPerPage} />
          </div>
        </Wrapper>
      </div>
    </>
  );
};

const mapStateToProps = ({ employeesDC }: StoreTypes) => ({
  employeesDC: employeesDC.employeesDC,
  isLoading: employeesDC.isLoading
});

const mapDispatchToProps = {
  getEmployeesDC: employeesDCRequest,
  resetErrorsAndStatus: resetErrorAndStatus
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

export default connector(EmployeesDC);
