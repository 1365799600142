import { DealerCenter } from "store/dealer-center/types";
import moment from "moment";

export const columns = [
  {
    title: "Название",
    dataIndex: "name",
    render: ({ name }: DealerCenter): string => name || ""
  },
  {
    title: "Город",
    dataIndex: "city",
    render: ({ city }: DealerCenter): string => city || ""
  },
  {
    title: "SAP-код",
    dataIndex: "sap_code",
    render: ({ sap_code }: DealerCenter): string => sap_code || ""
  },
  {
    title: "Статус",
    dataIndex: "status",
    render: ({ active }: DealerCenter): string => (active ? "Активный" : "Деактивирован")
  },
  {
    title: "Дата создания",
    dataIndex: "createdDate",
    render: ({ created_at }: DealerCenter): string => (created_at ? moment(created_at).format("DD.MM.YYYY") : "")
  },
  {
    title: "Дата обновления",
    dataIndex: "updatedDate",
    render: ({ updated_at }: DealerCenter): string => (updated_at ? moment(updated_at).format("DD.MM.YYYY") : "")
  }
];
