import { put, takeLatest, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { Employees } from "services/api/employees";
import { TYPES_EMPLOYEES_DC } from "store/employeesDC/constants";
import {
  employeesDCLoad,
  employeesDCLoadStop,
  employeesDCRequest,
  employeesDCSuccess,
  employeesDCError
} from "./actions";

function* getEmployeesDC(action: ReturnType<typeof employeesDCRequest>): SagaIterator {
  const { payload } = action;
  yield put(employeesDCLoad());
  try {
    const response = yield call(Employees.getEmployeesDC, payload);
    yield put(employeesDCSuccess(response));
  } catch (e) {
    yield put(employeesDCError({ message: "Не удалось загрузить данные" }));
  } finally {
    yield put(employeesDCLoadStop());
  }
}

function* employeesDCSaga(): SagaIterator {
  yield takeLatest(TYPES_EMPLOYEES_DC.EMPLOYEES_DC_REQUEST, getEmployeesDC);
}

export { employeesDCSaga };
