import { TYPES_DC } from "store/dealer-center/constants";
import { ActionsType, DealerCenter, Store } from "./types";
import { RESET_ERROR_AND_STATUS } from "../constants";

const initialState: Store = {
  isLoading: false,
  error: null,
  currentDealerCenter: null,
  dealerCenters: {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    total: 0,
    last_update: ""
  },
  selectedRows: [],
  deleteError: null,
  status: null
};

export const dealerCenterReducer = (state: Store = initialState, action: ActionsType): Store => {
  switch (action.type) {
    case TYPES_DC.DEALER_CENTER_LOAD: {
      return { ...state, isLoading: true, error: null };
    }
    case TYPES_DC.DEALER_CENTER_LOAD_STOP: {
      return { ...state, isLoading: false };
    }
    case TYPES_DC.DEALER_CENTER_SUCCESS: {
      return { ...state, dealerCenters: action.payload };
    }
    case TYPES_DC.DEALER_CENTER_ERROR: {
      return { ...state, error: { ...state.error, ...action.payload } };
    }
    case TYPES_DC.DEALER_CENTER_BY_ID_SUCCESS: {
      return { ...state, currentDealerCenter: action.payload };
    }
    case RESET_ERROR_AND_STATUS: {
      return { ...state, error: null, deleteError: null, status: null };
    }
    case TYPES_DC.DELETE_DEALER_CENTER_SUCCESS: {
      return {
        ...state,
        dealerCenters: {
          ...state.dealerCenters,
          data: state.dealerCenters.data.filter((dc: DealerCenter) => dc.id !== String(action.payload))
        }
      };
    }
    case TYPES_DC.SET_DC_ROWS: {
      return {
        ...state,
        selectedRows: action.payload
      };
    }
    case TYPES_DC.DEALERS_CLEAR_ROWS: {
      return {
        ...state,
        selectedRows: []
      };
    }
    case TYPES_DC.FILTER_DC_ROWS: {
      return {
        ...state,
        selectedRows: state.selectedRows.filter(item => item !== action.payload)
      };
    }
    case TYPES_DC.DELETE_DEALER_CENTER_ERROR: {
      return { ...state, deleteError: action.payload };
    }
    case TYPES_DC.DEALER_CENTER_STATUS: {
      return { ...state, status: action.payload };
    }
    case TYPES_DC.SYNCHRONIZE_DEALER_CENTER_SUCCESS: {
      return { ...state, dealerCenters: { ...state.dealerCenters, last_update: action.payload.last_update } };
    }
    default: {
      return state;
    }
  }
};
